<template>
  <div class="section-title">
    <div class="section-title__container">
      <div class="section-title__container__title">
        {{ title }}
      </div>
      <nuxt-link v-if="isSeeMore" :to="link" class="section-title__container__see-more">Xem Thêm</nuxt-link>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  link: {
    type: String,
    default: ''
  },
  isSeeMore: {
    type: Boolean,
    default: false
  }
})
const router = useRouter()
</script>
<style lang="scss" scoped src="~/assets/scss/components/common/section-title.scss"></style>
